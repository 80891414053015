import React, { useEffect, useState } from 'react';
import { Tag } from '../../components';
import { useQuery } from '@tanstack/react-query';
import { BASE_SERVICE } from '../../services';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { motion } from 'framer-motion';
import { imageUrl } from 'utils/config';
import { ClipLoader } from 'react-spinners';

export default function Galeri() {
  const [tags, setTags] = useState('All');
  const [imageZoom, setImageZoom] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [request, setRequest] = useState({
    page: 1,
    size: 10,
  });

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['perum'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        { ...request, tags },
        `/galery/findall`,
      ).then((res) => res),
  });

  useEffect(() => {
    refetch();
  }, [tags]);

  if (isLoading) {
    return (
      <div className="h-[80vh] w-full items-center justify-center flex">
        <ClipLoader
          color={`red`}
          loading={isLoading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  const handleAddTags = (name) => {
    setTags((prev) => (prev === name && prev !== 'All' ? '' : name));
  };

  const handleClickImage = (id) => {
    const img = data?.data?.values;
    const indexing = img.findIndex((item) => item.id_foto === id);

    let arrSlice = [];
    let idx = indexing;
    let length = img.length;
    let fullIdx = length - 1;

    for (const a of img) {
      if (idx <= fullIdx) {
        arrSlice.push(img[idx]);
        idx++;
      } else {
        idx = 0;
        arrSlice.push(img[idx]);
        idx++;
      }
    }
    window.scrollTo(0, Number(document.body.scrollHeight) / 10);
    setImageZoom(arrSlice);
    setShowDetail(true);
  };

  return (
    <motion.div
      initial={{ y: -200, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring' },
      }}
      className="min-h-[80vh] flex items-start justify-center w-full"
    >
      <div className="mt-20 w-[85%] flex justify-center items-center flex-col">
        <p className="text-2xl w-full text-center">PHOTO GALLERY</p>

        <div className="mt-10 flex w-full items-center justify-evenly w-[67%]">
          <Tag child="All" isTags={tags} onClick={handleAddTags} />
          <Tag child="Berita" isTags={tags} onClick={handleAddTags} />
          <Tag child="Event" isTags={tags} onClick={handleAddTags} />
          <Tag child="Informasi" isTags={tags} onClick={handleAddTags} />
          <Tag child="Inovasi" isTags={tags} onClick={handleAddTags} />
        </div>

        <div className="mt-4 grid grid-cols-4 gap-4 w-[85%] mb-12">
          {data?.data?.values?.map((item, index) => (
            <div
              key={item.id}
              onClick={() => handleClickImage(item.id_foto)}
              className="bg-cover bg-center h-[30vh]"
              style={{
                backgroundImage: `url(${imageUrl}/assets/${item.foto})`,
              }}
            >
              <div className="bg-stone-600 h-[30vh] z-[-100] opacity-0 flex items-center justify-center hover:opacity-100 hover:bg-[rgba(0,0,0,0.6)] cursor-pointer">
                <p className="text-xl text-white">Lihat Detail</p>
              </div>
            </div>
          ))}
        </div>

        <div
          className={`w-full ${
            !showDetail && 'hidden'
          } h-full flex items-center justify-center absolute top-0 bg-[rgba(0,0,0,0.7)] z-50`}
        >
          <Swiper
            cssMode={true}
            navigation={true}
            // pagination={true}
            rewind={true}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Mousewheel, Keyboard]}
            className="mySwiper"
          >
            {imageZoom?.map((item) => {
              return (
                <SwiperSlide key={item.id_foto}>
                  <div
                    onClick={() => {
                      setShowDetail((prev) => !prev);
                    }}
                    className="h-full items-center flex justify-center py-12"
                  >
                    <img
                      src={`${imageUrl}/assets/${item.foto}`}
                      alt="ima"
                      className="h-[80vh] w-[80vh] object-contain"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </motion.div>
  );
}
