import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import React, { Suspense } from 'react';
import {
  NotFound,
  Loader,
  ScrollToTop,
  Navbar,
  Footer,
  BlogDetail,
  BlogIndex,
} from './components';
import StatistikPerumahan from './pages/Data/StatistikPerumahan';
import Blog from './pages/Blog';
import Kontak from './pages/Kontak';
import Tentang from './pages/Tentang';
import Data from './pages/Data';
import Detail from './pages/DataDetail';
import Galeri from './pages/Galeri';
import Download from 'components/molecules/Download';
import BeritaDetail from 'pages/Berita';

const Home = React.lazy(() => import('./pages/Home'));
const Paket = React.lazy(() => import('./pages/Paket'));
const DaftarPage = React.lazy(() => import('./pages/Daftar'));

function App() {
  const location = useLocation();
  const isFalse = location.pathname.includes('404');
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tentang" element={<Tentang />} />
          <Route path="/paket" element={<Paket />} />
          <Route path="/kontak" element={<Kontak />} />
          <Route path="/download" element={<Download/>} />
          <Route path="/galeri" element={<Galeri />} />
          <Route path="/data" element={<Data />}></Route>
          <Route path="/stats" element={<StatistikPerumahan />} />
          <Route path="/register" element={<DaftarPage />} />
          <Route path="/detail/:id" element={<Detail />} />
          <Route path="/blog" element={<Blog />}>
            <Route index element={<BlogIndex />} />
            <Route path=":blog_path" element={<BeritaDetail />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/" />}></Route>
        </Routes>
      </Suspense>
      <Footer />
    </>
  );
}

export default App;
