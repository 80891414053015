import React from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from '@tanstack/react-query';
import { BASE_SERVICE } from '../../../services';

export default function StatistikPerumahan() {
  const {
    isLoading,
    data: dataDashboard,
    refetch: refetchDashboard,
  } = useQuery({
    queryKey: ['perum'],
    queryFn: async () =>
      await BASE_SERVICE._onGetServiceMethod(`/dashboard/statsdistrict`).then(
        (res) => res,
      ),
  });

  if(isLoading) return <div>Loading...</div>


  const generateVal = (db) => {
    console.log(db, "DB")
    const arrComercial = [];
    const arrSubs = [];
    const totalAll = [];

    for(const a of db){
      const valA = Number(a.total_komersil ?? 0)
      const valB = Number(a.total_subsidi ?? 0)
      arrComercial.push(valA);
      arrSubs.push(valB);
      totalAll.push(valA + valB);
    }

    return [
      {
        name: 'Komersil',
        data: arrComercial
      },
      {
        name: 'Subsidi',
        data: arrSubs
      },
      {
        name: 'Total',
        data: totalAll
      }
    ]
  }

  const generateValSer = (db) => {
    console.log(db, "DB")
    const arrComercial = [];
    const arrSubs = [];
    const totalAll = [];
    const arrSerah = [];

    for(const a of db){
      const valA = Number(a.total_serah_terima ?? 0)
      const valB = Number(a.total_subsidi ?? 0)
      const valC = Number(a.total_serah_terima ?? 0)
      arrComercial.push(valA);
      arrSerah.push(valC);
      arrSubs.push(valB);
      totalAll.push(valA + valB);
    }

    return [
      {
        name: 'Jumlah Serah Terima',
        data: arrSerah
      },
      {
        name: 'Total',
        data: totalAll
      }
    ]
  }

  
  const state = {
    options: {
      chart: {
        id: 'apexchart-example',
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      xaxis: {
        categories: dataDashboard?.data?.values?.map((item) => item.nama),
      },
    },
    series:  generateVal(dataDashboard?.data?.values)
  };

  const stateSer = {
    options: {
      chart: {
        id: 'apexchart-example',
        toolbar: {
          show: false,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
      },
      xaxis: {
        categories: dataDashboard?.data?.values?.map((item) => item.nama),
      },
    },
    series:  generateValSer(dataDashboard?.data?.values)
  };


  return (
    <div className="items-center justify-center flex">
      <div className="w-[85%] mt-32 mb-32">
        <h1 className="text-4xl font-semibold drop-shadow-md mb-5">
          Statistik <span className="text-rose-800">Perumahan</span>
        </h1>
        <div className="rounded-lg bg-white shadow-lg lg:p-6 sm:p-2 block lg:w-full p-3">
          <div>
            <h1 className="text-4xl font-semibold drop-shadow-md mb-5 text-center">
              Grafik Unit Berdasarkan Kategori
            </h1>
          </div>
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            className="w-full"
            height={520}
          />
        </div>



        <div className="rounded-lg bg-white shadow-lg mt-6 lg:p-6 sm:p-2 block lg:w-full p-3">
          <div>
            <h1 className="text-4xl font-semibold drop-shadow-md mb-5 text-center">
              Grafik Unit Berdasarkan Serah Terima
            </h1>
          </div>
          <Chart
            options={stateSer.options}
            series={stateSer.series}
            type="bar"
            className="w-full"
            height={520}
          />
        </div>
      </div>
    </div>
  );
}
