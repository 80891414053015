import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PaginationComponent from 'components/pagination';
import { Link } from 'react-router-dom';

//service
import { BASE_SERVICE } from '../../services';
import { useQuery } from '@tanstack/react-query';
import { imageUrl } from 'utils/config';

const Data = () => {
  const [districtValue, setDistrictValue] = useState('');
  const [villageValue, setVillageValue] = useState('');
  const [houseName, setHouseName] = useState('');
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);

  //summary for dashboard stats

  // const showAllPerum =
  const { isPending, isError, data, error, refetch } = useQuery({
    queryKey: ['perum'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        {
          pagination,
          houseName,
          villageValue,
          districtValue,
        },
        `/perum/showAll`,
      ).then((res) => res),
  });

  const { data: village, refetch: refetchVillage } = useQuery({
    queryKey: ['village'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        {
          districtValue,
        },
        `/village/showAll`,
      ).then((res) => res),
  });

  const { data: district, refetch: refetchDistrict } = useQuery({
    queryKey: ['district'],
    queryFn: async () =>
      await BASE_SERVICE._onGetService(
        {
          districtValue,
        },
        `/district/findAll`,
      ).then((res) => res),
  });

  useEffect(() => {
    const timer = setTimeout(async () => {
      await refetchVillage();
    }, 500);

    return () => clearTimeout(timer);
  }, [districtValue]);

  const handleSelectChangeDistrict = (event) => {
    setDistrictValue(event.target.value);
  };

  const handleSelectChangeVillage = (event) => {
    setVillageValue(event.target.value);
  };

  const _handleClear = (e) => {
    e.preventDefault();
    setDistrictValue('');
    setVillageValue('');
    setHouseName('');
  };

  const _handleFilter = async (e) => {
    e.preventDefault();
    await refetch();
    // You can also redirect the user to another page after successful login.
  };

  const handlePageChange = async (page) => {
    await setPagination({
      size: 10,
      page: page,
    })
    await setCurrentPage(page);
    await refetch();
  };

  // if (isLoading) return <span>Loading...</span>;

  if (isPending) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  // console.log(district?.data?.values, "VAL DIS")

  return (
    <motion.div
      className="mb-12"
      initial={{ y: -200, opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 1, type: 'spring' },
      }}
    >
      <div className="pt-16">
        <section className="bg-gray-100">
          <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-semibold drop-shadow-md mb-5">
              Data <span className="text-rose-800">Perumahan</span>
            </h1>
            <div className="w-full">
              <div className="rounded-lg bg-white shadow-lg lg:p-6 sm:p-2 block lg:w-full p-3">
                <h3 className="max-w-xl text-lg padding-bottom: 20px">
                  Filter
                </h3>
                <div className="mt-8"></div>
                <form onSubmit={_handleFilter} className="space-y-4">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-90">
                        Nama Perumahan
                      </label>
                      <input
                        value={houseName}
                        onChange={(e) => {
                          setHouseName(e.target.value);
                        }}
                        placeholder="Nama Perumahan"
                        className="bg-gray-500 outline-none border placeholder-white border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-90">
                        Kecamatan
                      </label>
                      <select
                        value={districtValue}
                        onChange={handleSelectChangeDistrict}
                        name="districtValue"
                        id="districtValue"
                        className="bg-gray-500 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        {district?.data?.values?.map((_val, _key) => (
                          <option value={_val.id_district} key={_key}>
                            {_val.nama}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-90">
                        Desa
                      </label>
                      <select
                        value={villageValue}
                        onChange={handleSelectChangeVillage}
                        name="villageValue"
                        id="villageValue"
                        className="bg-gray-500 border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      >
                        {village?.data?.values?.map((_val, _key) => (
                          <option value={_val.id_village} key={_key}>
                            {_val.nama}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="inline-block rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] bg-black"
                  >
                    Filter
                  </button>
                  <button
                    onClick={_handleClear}
                    className="inline-block ml-2 rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] bg-rose-600"
                  >
                    Clear
                  </button>
                </form>
                <div className="mt-5">
                  <Link to="/stats">
                    Lihat Statistik Perumahan
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-5"></div>

            <div className="rounded-lg bg-gray-200 p-4 shadow-lg">
              <div className="container m-auto grid sm:grid-cols-3">
                {data?.data?.values?.map((res, idx) => (
                  <div
                    id={idx}
                    key={idx}
                    className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow mb-2"
                  >
                    <motion.div
                      initial={{ x: '+100vw' }}
                      animate={{ x: 0 }}
                      transition={{ duration: 1 }}
                    >
                      <img
                        style={{
                          height: 300,
                          width: '100%',
                          objectFit: 'cover',
                        }}
                        className="p-2 rounded-t-lg"
                        src={`${imageUrl}/assets/${res.file}`}
                        alt="product"
                      />
                      <div className="px-5 pb-5">
                        <h5 className="text-xl font-semibold tracking-tight text-gray-900">
                          {res.nama}
                        </h5>
                        <div className="flex items-center mt-2.5 mb-5">
                          <span className="bg-teal-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                            Subs {res.subsidi}
                          </span>
                          <span className="bg-amber-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                              />
                            </svg>{' '}
                            Kom {res.komersil}
                          </span>
                          <span className="bg-green-500 text-gray-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 border border-gray-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                              />
                            </svg>
                            Hubungi
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-3xl font-bold text-gray-900"></span>

                          <Link
                            to={`/detail/${res.id_perum}`}
                            className="block w-full rounded bg-rose-600 px-12 py-2 text-sm font-medium text-white shadow hover:bg-rose-700 focus:outline-none focus:ring active:bg-rose-500 sm:w-auto"
                          >
                            Lihat Detail
                          </Link>
                        </div>
                      </div>
                    </motion.div>
                  </div>
                ))}
              </div>
              <PaginationComponent
                currentPage={currentPage}
                totalPages={Math.ceil(data?.data?.meta?.totalData / data?.data?.meta?.size)}
                onPageChange={handlePageChange}
                totalItems={data?.data?.meta?.totalData}
                itemsPerPage={pagination.size}
              />
            </div>
          </div>
        </section>
      </div>
    </motion.div>
  );
};

export default Data;
