import React from 'react';
import logoMSN from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import {
  FaPhoneAlt,
  FaLinkedin,
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaYoutube,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { ButtonWa } from '../../../components';

const Footer = () => {
  return (
    <div>
      <footer
        className="p-8 footer text-base-100 "
        style={{ backgroundColor: '#e5e7eb' }}
      >
        <div className="items-center py-0">
          <Link to="/">
            <img src={logoMSN} alt="MSN PRODUCTION" className="w-18 sm:w-30" />
          </Link>
          <h4 className="text-1xl font-bold text-center text-black">
            DINAS PERKIM
          </h4>
          <p className="text-1xl font-bold text-center text-black">
            KABUPATEN NGAWI
          </p>
        </div>

        <div>
          <span className="font-bold text-black">Tautan</span>
          <Link
            to=""
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Beranda</span>
          </Link>
          <Link
            to="/data"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Perumahan Pengembang</span>
          </Link>
          <Link
            to="/kontak"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Kontak</span>
          </Link>
          <Link
            to="/blog"
            className="link link-hover hover:text-primary text-accent"
          >
            <span>Informasi</span>
          </Link>
        </div>

        <div>
          <span className="font-bold text-accent">Kontak Kami</span>
          <span className="text-accent">
            Jalan Raya Ngawi – Cepu Km 3
            <br />
            Desa Ngawi, Kecamatan Ngawi, <br />
            Kabupaten Ngawi Kode Pos 63251.
          </span>
          <div className="flex items-center my-1">
            <FaPhoneAlt className="mr-6 text-2xl text-accent"></FaPhoneAlt>
            <h3 className="font-medium text-accent">+62 857 3570 9674</h3>
          </div>
          <div className="flex items-center my-1">
            <MdEmail className="mr-6 text-2xl text-accent"></MdEmail>
            <h3 className="font-medium text-accent">admin@perkim.com</h3>
          </div>
        </div>
        <div className="flex items-center mt-4">
          <a
            href="https://www.youtube.com/@pemerintahkabupatenngawi7841"
            target="blank"
            className="text-2xl text-accent hover:text-rose-700 hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
          >
            <FaYoutube></FaYoutube>
          </a>
          <a
            href="https://web.facebook.compemkab?_rdc=1&_rdr"
            target="blank"
            className="text-2xl text-accent hover:text-blue-600 hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
          >
            <FaFacebookSquare></FaFacebookSquare>
          </a>
          <a
            href="https://www.instagram.com/perkim_ngawi/"
            target="blank"
            className="text-2xl text-accent hover:text-rose-900 hover:-translate-y-1.5 shadow-lg mx-1 duration-300"
          >
            <FaInstagramSquare></FaInstagramSquare>
          </a>
        </div>
      </footer>
      <footer className="px-5 py-3 text-sm text-center text-base-100 border-base-300 bg-neutral ">
        <div className="w-full h-[1px] bg-gray-600"></div>
        <div className="flex flex-col items-center justify-center mt-3 md:flex-row text-accent">
          <p>
            &copy; Copyright 2023, IPSoft - Software Developer. All Rights
            Reserved
          </p>
        </div>
      </footer>

      <ButtonWa />
    </div>
  );
};

export default Footer;
