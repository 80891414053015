import React from 'react';
import ImageBanner from '../../../../assets/images/visual-banner.svg';
import { useQuery } from '@tanstack/react-query';
import { BASE_SERVICE } from 'services';
import { Link } from 'react-router-dom';

function AlurInformasi() {
  const { data: syarat, refetch: refetchSyarat } = useQuery({
    queryKey: ['syarat'],
    queryFn: async () =>
      await BASE_SERVICE._onGetServiceMethod(`/syarat/findallsyarat`).then(
        (res) => res,
      ),
  });

  return (
    <div className="my-8">
      <h1 className="font-semibold text-2xl mb-4">Informasi Alur</h1>
      <div className="flex justify-between items-center flex-row">
        <div className="w-[45%] flex items-center justify-center">
          <img
            className="object-cover w-[85%] h-[40%]"
            src={ImageBanner}
            alt="Banner"
            width={300}
          />
        </div>
        <div className="w-[45%]">
          <div className="my-8 flex text-justify flex-col">
            <h1 className="font-semibold text-xl mb-2">
              Permohonan Pengesahan Rencana
            </h1>
            <span>
              Setiap Pengembang / Developer yang akan menyelenggarakan Perumahan
              wajib menyusun Rencana Tapak ( Site Plan ) serta menyerahkan
              Prasarana, Sarana dan Utilitas yang dibangun di Perumahan
              tersebut. hal ini diatur dalam Perbup Ngawi Nomor 186 Tahun 2022
              tentang Penyelenggaraan Perumahan
            </span>
          </div>

          <h1 className="font-semibold text-xl mb-2">
            Syarat Pengajuan Izin Perumahan
          </h1>

          {syarat?.data?.values?.map((item) => {
            return (
              <p className="text-base my-2" key={item.id_syarat}>
                {item.nama}
              </p>
            );
          })}

          <div className="mt-8">
            <Link
              to="/download"
              className="bg-rose-600 text-white p-4 rounded-md"
            >
              Download File
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlurInformasi;
