import { Fragment, useEffect } from 'react';
import { imageUrl } from 'utils/config';
import { generateDate } from 'hooks/generateDate';
import { useNavigate } from 'react-router-dom';

export const Card = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    let id = document.getElementById(props.idKonten);
    let kontenSingkat = id.textContent.split(" ");
    kontenSingkat = kontenSingkat.filter((item) => item !== '');
    let arrTxt = [];
    let txt = '';
    for(let i=0; i<15;i++){
      arrTxt.push(kontenSingkat[i]);
    }
    txt = arrTxt.join(" ")
    id.textContent = txt;
  },[props])

  const onGoToDetail = (idContent) => {
    console.log(idContent, 'masuk')
    navigate(`/blog/${idContent}`);
  }


  return (
    <Fragment>
      <div className="shadow-lg max-w-sm rounded overflow-hidden" onClick={()=>onGoToDetail(props?.idKonten)}>
        <img
          className="w-full h-[320px] object-cover"
          src={`${imageUrl}/assets/${props.url}`}
          alt="Sunset in the mountains"
        />
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{props.title}</div>
          <div><p>{generateDate(props.date)}</p></div>
          <span
            id={props.idKonten}
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          ></span>
        </div>
      </div>
    </Fragment>
  );
};
