import React, { useState, useEffect } from 'react';
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from 'react-router-dom';
// import placeholderImage from '../../../assets/images/placeholder.jpg';
import { FaAngleDoubleRight, FaAngleDoubleLeft } from 'react-icons/fa';
import { BottomLine } from '../../../components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ImageBanner from '../../../assets/images/visual-banner.svg';
import { Card } from '../../../components/atoms/Card/Card';
import {
  headingAnimation,
  sectionBodyAnimation,
} from '../../../hooks/useAnimation';
import { BASE_SERVICE } from '../../../services';
import AlurInformasi from './components/AlurInformasi';

const BlogIndex = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState('');

  const [ref, inView] = useInView();
  const [viewDiv, setViewDiv] = useState(false);
  const animation = useAnimation();
  const totalPages = Math.ceil(Number(totalData) / pageSize);

  const handleNextPageClick = () => {
    console.log(currentPage, totalPages, totalData, "TOTAL")
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      _onGetContentData(currentPage + 1);
    }
  };

  const handlePreviousPageClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      _onGetContentData(currentPage - 1);
    }
  };

  const _onGetContentData = (page) => {
    const pagination = {
      size: pageSize,
      page: page,
    };
    BASE_SERVICE._onGetService(pagination, `/konten/showAll`)
      .then((res) => {
        setLoading(false);
        if (res?.data?.code === 200) {
          setData(res?.data?.values);
          setTotalData(res?.data?.meta?.totalData);
        }
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 401) {
          localStorage.removeItem('accessToken');
        }
      });
  };

  useEffect(() => {
    _onGetContentData(1);
    if (inView) {
      setViewDiv(true);
    } else {
      setViewDiv(false);
    }
  }, [inView, animation]);

  console.log('DATA BLOG::', data);

  return (
    <div className="py-16 parent">
      <motion.div
        initial="hidden"
        animate={viewDiv && 'visible'}
        variants={headingAnimation}
      >
        <h1 className="text-3xl font-semibold ">
          Berita <span className="text-rose-700">Terkini</span>
        </h1>
        <BottomLine />
      </motion.div>
      <div className="mt-10">
        <motion.div
          className="grid grid-cols-1 gap-8 md:grid-cols-3"
          ref={ref}
          initial="hidden"
          animate={viewDiv && 'visible'}
          variants={sectionBodyAnimation}
        >
          {data.map((blog) => {
            const { id_content, judul, create_at, deskripsi, path, gambar } =
              blog;
            return (
              <Card
                key={id_content}
                idKonten={id_content}
                title={judul}
                date={create_at}
                description={deskripsi}
                url={gambar}
              />
            );
          })}
        </motion.div>
      </div>

      <motion.div
        ref={ref}
        initial="hidden"
        animate={viewDiv && 'visible'}
        variants={sectionBodyAnimation}
      >
        <div className="flex items-center justify-start mt-10">
          <button
            onClick={handlePreviousPageClick}
            disabled={currentPage === 1}
            className="flex items-center gap-3 text-white duration-500 border-2 btn bg-rose-600 hover:bg-transparent hover:border-rose-600 hover:text-black"
          >
            Sebelumnya
          </button>
          <button
            onClick={handleNextPageClick}
            disabled={currentPage === totalPages}
            className="flex ml-4 items-center gap-3 text-white duration-500 border-2 btn bg-rose-600 hover:bg-transparent hover:border-rose-600 hover:text-black"
          >
            Selanjutnya
          </button>
        </div>

        <AlurInformasi />
      </motion.div>
    </div>
  );
};

export default BlogIndex;
